/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/types/ite/'], ['en', 'https://www.hear.com/hearing-aids/types/ite/'], ['en-US', 'https://www.hear.com/hearing-aids/types/ite/'], ['en-CA', 'https://ca.hear.com/hearing-aids/types/ite/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids come in three basic styles: Behind-the-Ear (BTE), in-the-ear (ITE), and completely-in-the-canal (CIC). While all styles are incredibly small, many people prefer devices that go in the ear for both functional and cosmetic reasons.\nBecause lifestyle needs, degree of hearing loss, and the physical size of the ear canal differ from individual to individual, hearing aids are not one size fits all. Therefore, an audiologist or hearing specialist will need to evaluate your hearing and discuss your options during your first appointment. In the meantime, you can learn more about the pros and cons of in-the-ear hearing aids below. If you would like to speak to an expert about trying hearing aids for 30 days, simply click on the link below."), "\n", React.createElement(ButtonCta, {
    copy: "TRY HEARING AIDS",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "in-the-ear-hearing-aids-come-in-three-different-styles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#in-the-ear-hearing-aids-come-in-three-different-styles",
    "aria-label": "in the ear hearing aids come in three different styles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "In-the-Ear hearing aids come in three different styles:"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "1-completely-in-the-canal-cic-devices",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#1-completely-in-the-canal-cic-devices",
    "aria-label": "1 completely in the canal cic devices permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "1. Completely-In-the-Canal (CIC) devices"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The CIC hearing aid is custom molded to completely fit inside your ear canal. CICs are a good solution for mild to moderate hearing loss."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The smallest and most discreet hearing aids"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Least likely to pick up wind noise"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "If you select one of the CIC models designed to connect to a smartphone app, you are essentially getting a mini supercomputer. These hearing aids connect directly to an iPhone, iPad, or iPod via an app, enabling you to digitally control the volume and directional microphones for optimum clarity. Your mini supercomputer remembers your preferences and automatically adjusts to your changing environment"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Because CICs use very ", React.createElement(_components.a, {
    href: "/hearing-aids/batteries",
    className: "c-md-a"
  }, "small batteries"), ", a certain degree of physical dexterity is required to handle them safely"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The device sits in your ear canal, making them somewhat susceptible to moisture and earwax which can clog the speaker"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "2-in-the-canal-itc-devices",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2-in-the-canal-itc-devices",
    "aria-label": "2 in the canal itc devices permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "2. In-the-Canal (ITC) devices"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ITC hearing aid is also custom molded specifically to your preferences and fits partly in your ear canal. ITCs share many of the same attributes as the even smaller CICs but are easier to handle, especially when it comes to changing the battery."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The second smallest and discreet hearing aids"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Slightly larger with a longer lasting battery life"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "ITCs are a bit more likely to pick up wind noise than a CIC but still effectively reduces it automatically"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "If you select one of the ITC models designed to connect to a smartphone app, you are essentially getting a mini supercomputer. These hearing aids connect directly to an iPhone, iPad, or iPod via an app, enabling you to digitally control the volume and directional microphones for optimum clarity. Your mini supercomputer remembers your preferences and automatically adjusts to your changing environment"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Because the device sits partially in your ear canal, this makes them somewhat susceptible to moisture and earwax which can clog the speaker"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "3-in-the-ear-ite-devices",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-in-the-ear-ite-devices",
    "aria-label": "3 in the ear ite devices permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3. In-the-Ear (ITE) devices"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In-the-Ear hearing aids come in two custom-made and individually-fitted styles. The less obvious of the two ITEs rest in the lower part of your ear – the part that resembles half of a shell. The other variation of the ITE fills most of the bowl-shaped area in your outer ear. ITEs help with mild to moderate hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "ITEs have features that don’t fit on smaller style hearing aids, such as a volume control\nThey use a larger battery which gives you a longer battery life\nFor most people, the larger size makes handling the device easier\nDue to their exposed positioning, there is a resulting susceptibility to wind noise. Depending on the models offered by various manufacturers, some ITEs offer far better control over wind interference than others. This is something you need to discuss with your audiologist or hearing specialist.\nFor further information on ITE devices, ", React.createElement(_components.a, {
    href: "https://www.mayoclinic.org/diseases-conditions/hearing-loss/in-depth/hearing-aids/art-20044116",
    className: "c-md-a"
  }, "refer to this Mayo Clinic article.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "long-term-benefits-of-using-an-ite-device",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#long-term-benefits-of-using-an-ite-device",
    "aria-label": "long term benefits of using an ite device permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Long term benefits of using an ITE device"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Overall, all the multiple styles and variations of in-the-ear and in-the-canal hearing aids allow you to enjoy social and physical activities as you did prior to your hearing loss. It will be easier for you to hear clearly in conversations, listen to music, and watch your favorite films/TV shows. Other practical benefits include the following:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Improved career performance"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Improved overall ability to communicate"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Improved cognitive abilities, including memory and reasoning skills"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Decrease in Tinnitus – the high-pitched ringing sound that can accompany hearing loss"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Increased level of happiness"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Lifestyle really does influence the optimum hearing aid for your needs. If you love to golf but can pass on season tickets to the Symphony, or if your career has you on the phone or online conference calls for hours on end – it is imperative to share your specific needs with your hearing specialist."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "expert-advice-on-in-the-ear-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#expert-advice-on-in-the-ear-hearing-aids",
    "aria-label": "expert advice on in the ear hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Expert advice on in-the-ear hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids",
    className: "c-md-a"
  }, "To discover the best hearing aid for you"), ", contact our hearing experts at hear.com. We will provide you with expert information for the best hearing aid solution and connect you with the best audiologist or hearing specialist in your area."), "\n", React.createElement(ButtonCta, {
    copy: "GET THE BEST ITE HEARING AID",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
